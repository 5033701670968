import './QuizResultsScreen.css';
import {useAppDispatch, useAppSelector} from '../../hook';
import { getQuizResult, getQuizState} from '../../slices/PubQuizSlice';
import logo from '../start/logo256.png';
import {QuestionResultModel} from '../../model/QuizResults';
import {restartQuiz} from '../../middleware/SocketMiddleware';
import {QuizStates} from '../../model/QuizStates';

function getAnswer(question: QuestionResultModel): string {
    switch (question.answer) {
        case 0:
            return question.question.option0;
        case 1:
            return question.question.option1;
        case 2:
            return question.question.option2;
        case 3:
            return question.question.option3;
    }

    return 'eek!';
}

export function QuizResultsScreen(): JSX.Element {

    const quizResults = useAppSelector(getQuizResult);
    const state = useAppSelector(getQuizState);
    const dispatch = useAppDispatch();

    const enableButton = state === QuizStates.Finished;

    const gotoLobby = enableButton? () => {
        console.log('Restarting');
        dispatch(restartQuiz());
    } : ()=>{};

    const orderedScores = quizResults?.scores.sort((score1, score2) => score1.score - score2.score);

    const results = (orderedScores !== undefined) ?
        orderedScores.map((score) => (<li className="scoreRow" key={score.participant.participantId}>
            <span className="scoreName">{score.participant.name}</span><span className="scoreScore">{score.score}</span></li>
        )) : [];

    const answers = (quizResults !==undefined ) ?
        quizResults.questions.map((question, index: number) => (<li className="questionRow" key={index}>
            <span className="questionText">{question.question.question}</span><span className="answer">{getAnswer(question)}</span>
        </li>)): [];


    return (<div className="resultsScreen">
        <h1>
            <img src={logo} className="App-logo" alt="logo"/>
            <span>Pub Quiz<br/>Results</span>
        </h1>
        <div className="results">
            <h2>Scores</h2>
            <ul className="scores">{results}</ul>
            <h2>Answers</h2>
            <ul className="resultAnswers">{answers}</ul>
        </div>
        <button className="pill" disabled={!enableButton} onClick={gotoLobby}>
            <span>{enableButton ? 'OK' : 'RESTARTING ..'}</span>
        </button>
    </div>);
}
