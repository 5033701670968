import './Timer.css';
import {useEffect, useState} from 'react';

export function Timer({endTimestamp, duration}: { endTimestamp: number | undefined; duration: number}): JSX.Element {
    const [seconds, setSeconds] = useState(0);

    const workout = (): void => {
        if (endTimestamp === undefined) {return;}
        const difference = (endTimestamp - new Date().getTime());
        const differenceInSeconds = difference === 0?0 : Math.max(Math.round(difference/1000), 0);
        if (differenceInSeconds !== seconds) {
            setSeconds(differenceInSeconds);
        }
    };

    workout();

    useEffect(() => {
        const interval = setInterval(() => workout(), 1000);
        return () => clearInterval(interval);
    });

    return (<div className="progress-frame"><div className="outer-progress"><div className="inner-progress" style={{
        transform:`rotate(${  0.5 - (seconds/(duration * 2))  }turn)`}
    }></div></div></div>);
}

