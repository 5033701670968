import './LoadingScreen.css';
import logo from './logo256.png';

export function LoadingScreen(): JSX.Element {
    return (
        <div className="loadingScreen">
            <img src={logo} className="App-logo" alt="logo"/>
            <h1>
                Pub Quiz
            </h1>
            <p>connecting ...</p>
        </div>
    );
}
