import './LobbyScreen.css';
import logo from './logo256.png';
import {Participants} from '../participants/Participants';
import {getQuizState} from '../../slices/PubQuizSlice';
import {QuizStates} from '../../model/QuizStates';
import {startQuiz} from '../../middleware/SocketMiddleware';
import {useAppDispatch, useAppSelector} from '../../hook';


export function LobbyScreen(): JSX.Element {
    const state = useAppSelector(getQuizState);
    const dispatch = useAppDispatch();

    const enableButton = state === QuizStates.Lobby;

    const onClick = enableButton ? () => {
        dispatch(startQuiz());
    } : ()=>{};

    return (
        <div className="lobbyScreen">
            <h1>
                <img src={logo} className="App-logo" alt="logo"/>
                <span>Pub Quiz<br/>Lobby</span>
            </h1>
            <Participants/>
            <button className="pill" disabled={!enableButton} onClick={onClick}><span>{enableButton ? 'START GAME' : 'STARTING ..'}</span>
            </button>
        </div>
    );
}
